import React from 'react';

const CustomSelect = ({ className, ...props }) => {

  return (
    <select className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-fabric-yellow sm:text-sm sm:leading-6" {...props}>
      {props.children}
    </select>
  );
};

export default CustomSelect;

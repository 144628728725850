/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Outlet, useSearchParams } from "react-router-dom";
import { Stage } from '../utils/constants';

const navigation = [
  //   { name: 'Dashboard', href: '#', current: true },
  //   { name: 'Team', href: '#', current: false },
  //   { name: 'Projects', href: '#', current: false },
  //   { name: 'Calendar', href: '#', current: false },
  //   { name: 'Reports', href: '#', current: false },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ApplicationShell({ user, isLoginRequired }) {  
  const [searchParams] = useSearchParams();
  const asModalView = searchParams.get('mo') === '1';
  
  if(asModalView){
    document.documentElement.classList.remove('bg-gray-100');
  }
  let userNavigation = [
    { 
      name: 'Log in', 
      href: `${process.env.REACT_APP_FABRIC_BASE_URL}/login?redirect=${window.location.href}`,
      classNames: ''
    },
  ];
  if (user) {
    userNavigation = [
      {
        name: `Hello, ${user.firstName}!`,
        href: '#',
        classNames: 'font-bold'
      },
      { 
        name: 'Log out', 
        href: `${process.env.REACT_APP_FABRIC_BASE_URL}/logout?redirect=${window.location.href}`,
        classNames: '' 
      },
    ];
  }

  const getNavBarClassNames = (stage) => {
    if (stage === Stage.LOCAL) {
      return "bg-emerald-500 text-white shadow"
    }
    else if (stage === Stage.DEV) {
      return "bg-fabric-light-blue text-white shadow"
    }
    return "bg-fabric-dark-blue text-white shadow"
  }

  return (
    <>
      <div className='h-full'>
        {
          !asModalView &&
          <div className={getNavBarClassNames(process.env.REACT_APP_STAGE)}>
            { 
              process.env.REACT_APP_STAGE === Stage.LOCAL && 
              <span className="fixed top-5 left-5 items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">{process.env.REACT_APP_STAGE}</span>
            }
            { 
              process.env.REACT_APP_STAGE === Stage.DEV && 
              <span className="fixed top-5 left-5 items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">{process.env.REACT_APP_STAGE}</span>
            }
            <Disclosure as="nav" className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              {({ open }) => (
                <>
                  <div className="">
                    <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-fabric-dark-blue lg:border-opacity-25">
                      <div className="flex items-center px-2 lg:px-0">
                        <div className="flex-shrink-0">
                          <img
                            className="block h-10"
                            src={`${process.env.PUBLIC_URL}/logo-fabric.png`}
                            alt="Fabric Health"
                          />
                        </div>
                      </div>
                      <div className="block lg:ml-4">
                        <div className="flex items-center">

                          {/* Profile dropdown */}
                          <Menu as="div" className="relative">
                            <div className="flex items-center">
                              {user &&
                              
                                <Menu.Button className="relative flex rounded-full bg-fabric-dark-blue text-sm text-white focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-fabric-dark-blue" aria-haspopup="menu" aria-expanded="false" >
                                  <span className="absolute -inset-1.5" />
                                  <span className="sr-only">Open user menu</span>
                                  {user.imageUrl && <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />}
                                  {!user.imageUrl && <div className="h-10 w-10 flex items-center justify-center rounded-full bg-gray-400 text-white">
                                    {user.firstName.charAt(0)}
                                  </div>}
                                </Menu.Button>
                              }
                              {!user && isLoginRequired &&
                                <a href={`${process.env.REACT_APP_FABRIC_BASE_URL}/login?redirect=${window.location.href}`} className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-fabric-light-blue hover:bg-opacity-75" data-headlessui-state="open" data-open="">Sign in</a>
                              }
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {userNavigation.map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                      <a
                                        href={item.href}
                                        className={item.classNames + ' ' + classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        {item.name}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="lg:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                      {navigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-fabric-dark-blue text-white'
                              : 'text-white hover:bg-fabric-dark-blue hover:bg-opacity-75',
                            'block rounded-md py-2 px-3 text-base font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                    <div className="border-t border-fabric-light-blue pb-3 pt-4">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          {user && user.imageUrl &&
                            <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                          }
                          {user && !user.imageUrl &&
                            <div className="h-10 w-10 flex items-center justify-center rounded-full bg-gray-400 text-white">
                              {user.firstName.charAt(0)}
                            </div>
                          }
                        </div>
                        {user &&
                          <div className="ml-3">
                            <div className="text-base font-medium text-white">{user.firstName}</div>
                            <div className="text-sm font-medium text-fabric-light-blue">{user.email}</div>
                          </div>
                        }
                      </div>
                      <div className="mt-3 space-y-1 px-2">
                        {userNavigation.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-fabric-light-blue hover:bg-opacity-75"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        }
        <main className={asModalView ? '' : 'py-10'}>
          <div className={asModalView ? 'mx-auto grid-cols-1 gap-6 lg:max-w-7xl' : 'mx-auto grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl'}>
            <div className={asModalView ? 'bg-white sm:rounded-lg px-4 py-5' : 'bg-white shadow-sm sm:rounded-lg px-4 py-5'}>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

// components/inputs/CustomRjsfInputs.js
import CustomInput from './CustomInput';
import CustomLabel from './CustomLabel';
import CustomSelect from './CustomSelect';
import CustomCheckboxGroup from './CustomCheckboxGroup';
import CustomCheckbox from './CustomCheckbox';
import InputErrors from './InputErrors';
import CustomTextarea from './CustomTextarea';
import CustomRadioGroup from './CustomRadioGroup';
import CustomPhoneInput from './CustomPhoneInput';
import Markdown from 'react-markdown';
import { Autocomplete } from '@react-google-maps/api';
import { getAddressFromGooglePlace } from '../../utils/address';
import React, {  useState } from 'react';

export const CustomErrorListTemplate = (props) => {
  const { errors } = props;
  return <InputErrors errorMessages={errors} />;
}

export const CustomFieldTemplate = (props) => {
  const { id, label, help, description, errors, children, hidden, displayLabel } = props;
  return (
      <div className={hidden ? 'hidden' : '[&:not(:last-child)]:mb-5'}>
        {displayLabel &&
          <CustomLabel htmlFor={id} tooltip={help && help.props && help.props.help ? help.props.help : null}>
            {label}
          </CustomLabel>
        }
        {description}
        {children}

        {errors?.props?.errors && errors.props.errors.length > 0 && <InputErrors errorMessages={errors.props.errors} />}
      </div>
  )
};


export const CustomInputTemplate = (props) => {
  const { id, className, placeholder, style, value, onChange, name, pattern, type, required, readonly, disabled } = props;
  const handleChange = event => {
    let selectedValue = event.target.value;
    if (selectedValue?.trim() === '') {
      // treat empty string (or all whitespace) as undefined, which makes the 'required' handling in RJSF behave sanely
      selectedValue = undefined;
    }
    onChange(selectedValue);
  };
  return <CustomInput
    id={id}
    className={className}
    style={style}
    placeholder={placeholder}
    pattern={pattern}
    value={value}
    onChange={handleChange}
    name={name}
    type={type}
    required={required}
    readOnly={readonly}
    disabled={disabled}
  />;
}

export const CustomPhoneWidget = (props) => {
  return <CustomPhoneInput {...props} />;
}

export const CustomTextareaWidget = (props) => {
  const { id, placeholder, style, value, onChange, name, pattern, required, disabled } = props;
  const handleChange = event => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };
  return <CustomTextarea
    id={id}
    style={style}
    placeholder={placeholder}
    pattern={pattern}
    value={value}
    onChange={handleChange}
    name={name}
    required={required}
    disabled={disabled}
  />;
}


export const CustomSelectWidget = (props) => {
  const { options, onChange, id, name, label, required, placeholder, autofocus, disabled, value } = props;
  const handleChange = event => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };
  return (
    <CustomSelect onChange={handleChange} id={id} name={name} label={label} required={required} placeholder={placeholder} disabled={disabled} value={value}>
      <option disabled value=''>Select an option</option>
      {options.enumOptions && options.enumOptions.map(option => (
        <option key={option.value}
          value={option.value}>
          {option.label}
        </option>
      ))}
    </CustomSelect>
  );
};

export const CustomCheckboxWidget = (props) => {
  const { value, onChange, name, label, disabled } = props;
  const handleChange = value => {
    const selectedValue = value;
    onChange(selectedValue);
  }
  return (
    <CustomCheckbox value={value} name={name} label={label} onChange={handleChange} disabled={disabled} />
  )
}

export const CustomRadioGroupWidget = (props) => {
  const { options, value, onChange, required, name, disabled } = props;
  const handleChange = value => {
    const selectedValue = value;
    onChange(selectedValue);
  };
  return (
    <CustomRadioGroup options={options.enumOptions} value={value} required={required} name={name} onChange={handleChange} disabled={disabled}>
    </CustomRadioGroup>
  )
}

export const CustomCheckboxGroupWidget = props => {
  const { value, onChange, options, name, disabled } = props;
  const handleChange = updatedValue => {
    onChange(updatedValue);
  };

  return (
    <CustomCheckboxGroup options={options.enumOptions} value={value} name={name} onChange={handleChange} disabled={disabled} />
  );
};

export const CustomMarkdownWidget = (props) => {
  const { value, linksInSameTab } = props;

  return (
    <div className='custom-markdown'>
      <Markdown components={
        { a(props) { return <LinkRenderer href={props.href} children={props.children} newTab={linksInSameTab !== true} /> }}
      }>
        {value}
      </Markdown>
    </div>
  );
};

export const CustomAddressWidget = (props) => {
  const { value, id, name, disabled, required, placeholder, onChange, rawErrors } = props;
  const [autocompleteState, setAutocompleteState] = useState(null);
  const [autocompleteVal, setAutocompleteVal] = useState(
    (value && value['address']) || ''
  );

  const handleAutocompleteLoad = (autoComp) => {
    setAutocompleteState(autoComp);
  };

  const handleChange = updatedValue => {
    setAutocompleteVal(updatedValue);

    if(!!!updatedValue){
      const formData = {}; 
      formData['address'] = '';
      formData['street'] = '';
      formData['city'] = '';
      formData['state'] = '';
      formData['zip'] = '';

      if (rawErrors) rawErrors.length = 0; // HACK: kill any existing validation errors
      onChange(formData);
      hideSpinner();
    }
    else {
      const formData = {};
      formData['address'] = updatedValue;
      onChange(formData);
      
      showSpinner();
    }
  };

  const showSpinner = () => {
    const el = document.getElementById('addressLoadingSpinner');
    el.classList.remove('hidden');
  }

  const hideSpinner = () => {
    const el = document.getElementById('addressLoadingSpinner');
    el.classList.add('hidden');
  }

  const handlePlaceSelect = () => {
    if (autocompleteState !== null) {
      const place = autocompleteState.getPlace();
      const googleAddress = getAddressFromGooglePlace(place);
      handleChange(place.formatted_address)
      
      const formData = {};
      formData['address'] = place.formatted_address;
      formData['city'] = googleAddress.city;
      formData['state'] = googleAddress.state;
      formData['zip'] = googleAddress.zip;

      if (!!googleAddress.houseNum && !!googleAddress.streetName) {
        formData['street'] = `${googleAddress.houseNum} ${googleAddress.streetName}`; 
      }
      else {
        formData['street'] = '';
      }
      
      onChange(formData);
      hideSpinner();
    }
  };

  return (
    <div className="flex items-center">
      <Autocomplete
        onLoad={handleAutocompleteLoad}
        onPlaceChanged={handlePlaceSelect}
        className="grow"
      >
        <CustomInput
          type="text"
          
          value={autocompleteVal}
          onChange={(e) => handleChange(e.target.value)}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          name={name}
        />
      </Autocomplete>
      <div id="addressLoadingSpinner" className="hidden ml-2 mt-2">
        <svg width="20" height="20" viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="20" stroke="black" strokeWidth="4" fill="none" strokeDasharray="31.4" strokeLinecap="round">
            <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    </div>
  );
};


const LinkRenderer = ({ href, children, newTab }) => {
  return (
    <a href={href} target={newTab ? "_blank" : ""} rel="noopener noreferrer">
      {children}
    </a>
  )
};
import React, { useState } from 'react';
import { Field } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CustomCheckboxGroup({ options, value, name, onChange, disabled }) {
  // State to track the checked status of checkboxes
  const [checkedItems, setCheckedItems] = useState(value);

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let updatedCheckedItems;
    if (checked) {
      updatedCheckedItems = [...checkedItems, name];
    } else {
      updatedCheckedItems = checkedItems.filter((item) => item !== name);
    }
    setCheckedItems(updatedCheckedItems);
    onChange(updatedCheckedItems);
  };

  return (
    <fieldset name={name}>
      <div className="-space-y-px rounded-md bg-white mt-2">
        {options && options.map((option, optionIdx) => (
          <Field key={option.value}>
            <label htmlFor={option.value} className={classNames(
              optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              disabled ? 'cursor-default' : 'cursor-pointer',
              checkedItems.includes(option.value) 
                ? disabled
                  ? 'bg-gray-100'  // checked and disabled
                  : 'bg-fabric-light-range-50 border-fabric-light-range-200' // checked and not disabled
                : '',
              'flex items-center border p-4 focus:outline-none',
            )}
            >
              <input
                id={option.value}
                aria-describedby={option.label + '-description'}
                name={option.value}
                type="checkbox"
                disabled={disabled}
                className="custom-checkbox-input"
                checked={(checkedItems.includes(option.value))}
                onChange={handleCheckboxChange}
              />
              <span className="ml-3 text-sm leading-6 flex flex-col">
                <span className={classNames(checkedItems.includes(option.value) ? 'text-fabric-light-range-900' : 'text-gray-900')}>
                  {option.label}
                </span>
              </span>
            </label>
          </Field>
        ))}
      </div>
    </fieldset>
  );
}
import { RadioGroup } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CustomRadioGroup({ options, required, ...props }) {
  return (
    <RadioGroup {...props}>
      <div className="-space-y-px rounded-md bg-white mt-2">
        {options && options.filter(option => option.value) //hack to allow empty strings in enum without displaying an empty option
          .map((option, optionIdx) => (
            <RadioGroup.Option
              key={`${option.name}-${option.value}`} // Ensure key is unique
              value={option.value}
              required={required}
              className={({ checked }) =>
                classNames(
                  optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  props.disabled ? 'cursor-default' : 'cursor-pointer',
                  checked
                    ? props.disabled
                      ? 'bg-gray-100' // checked and disabled
                      : 'bg-fabric-light-range-50 border-fabric-light-range-200' // checked and not disabled
                    : '',
                  'flex items-center border p-4 focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? props.disabled
                          ? 'bg-gray-300 border-gray-300' // checked and disabled
                          : 'bg-fabric-light-range-500 border-transparent' // checked and not disabled
                        : props.disabled 
                          ? 'bg-gray-100 border-gray-300' // not checked and disabled
                          : 'bg-white border-gray-300', // not checked and not disabled 
                      active ? 'ring-2 ring-offset-2 ring-fabric-light-range-600' : '',
                      'h-4 w-4 shrink-0 rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span
                      className={classNames(
                        props.disabled && !checked ? 'opacity-0' : '',
                        "rounded-full bg-white w-1.5 h-1.5"
                      )}
                    />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-fabric-light-range-900 text-gray-900' : 'text-gray-900', 'block text-sm')}
                    >
                      {option.label}
                    </RadioGroup.Label>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
      </div>
    </RadioGroup>
  );
}

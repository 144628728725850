import React from 'react';

const CustomInput = (props) => {

  return (
    <input
      {...props}
      className="custom-text-input"
    />
  );
};

export default CustomInput;

import { CheckIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProgressBar({ steps, navigateToTab }) {
  return (
    <nav aria-label="Progress">
      <ol className="flex items-center w-fill">
        {steps.map((step, stepIdx) => (
          <li key={step.name}
            className={classNames(stepIdx !== steps.length - 1 ? 'flex-grow' : '', `${step.disabled ? 'pointer-events-none' : 'cursor-pointer'} relative`)}
            onClick={evt => !step.disabled && navigateToTab(stepIdx)}
            title={step.name}>
            {step.status === 'complete' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-fabric-dark-blue" />
                </div>
                <button
                  className="relative flex h-5 w-5 sm:h-8 sm:w-8 lg:h-12 lg:w-12 items-center justify-center rounded-full bg-fabric-dark-blue hover:bg-fabric-light-range-500"
                >
                  <CheckIcon className="h-5 w-5 lg:h-8 lg:w-8 text-white" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </button>
              </>
            ) : step.status === 'current' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className={classNames("h-0.5 w-full", steps[stepIdx + 1]?.disabled ? 'bg-gray-200' : 'bg-gray-400/85')} />
                </div>
                <button
                  className="relative flex h-5 w-5 sm:h-8 sm:w-8 lg:h-12 lg:w-12 items-center justify-center rounded-full bg-white border-2 border-fabric-dark-blue"
                  aria-current="step"
                >
                  <span className="h-2 w-2 sm:h-2.5 sm:w-2.5 lg:h-4 lg:w-4 rounded-full bg-fabric-dark-blue" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </button>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className={classNames("h-0.5 w-full", steps[stepIdx + 1]?.disabled ? 'bg-gray-200' : 'bg-gray-400/85')} />
                </div>
                <button
                  href="#"
                  className={classNames("group relative flex h-5 w-5 sm:h-8 sm:w-8 lg:h-12 lg:w-12 items-center justify-center rounded-full bg-white border-2 hover:border-fabric-light-range-500", step.disabled ? 'border-gray-200' : 'border-gray-400/85')}
                >
                  <span
                    className="h-2 w-2 sm:h-2.5 sm:w-2.5 lg:h-4 lg:w-4 rounded-full bg-transparent hidden group-hover:block group-hover:bg-fabric-light-range-500"
                    aria-hidden="true"
                  />
                  <span
                    className={classNames("h-2 w-2 sm:h-5 sm:w-5 lg:h-6 lg:w-6 text-center text-sm lg:text-base hidden sm:block group-hover:hidden", step.disabled ? 'text-gray-200' : 'text-gray-400/85')}
                    aria-hidden="true">{step.id}</span>
                  <span className="sr-only">{step.name}</span>
                </button>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
import React, { useState, useEffect, useRef } from 'react';
import PaginationButtons from '../navigation/PaginationButtons';
import { postFormSubmission, putFormSubmission, getFormTemplate, postSendVerificationCode } from '../../utils/api';
import CustomButton from '../buttons/CustomButton';
import Alert from '../alerts/Alert';
import CustomCheckbox from '../inputs/CustomCheckbox';
import { MemberDashboardMessages } from '../../utils/constants';

const VerifyPhone = ({
  person,
  isLastForm,
  setLoading,
  csrfToken,
  index,
  prevTabTitle,
  goToPrevTab,
  nextTabTitle,
  goToNextTab,
  verifyPhoneTemplateId,
  registrationForm,
  updateRegistrationForms,
  formSessionId,
  isReadOnly,
  asModalView }) => {

  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const [bypassPhoneVerification, setBypassPhoneVerification] = useState(registrationForm.form.bypass_phone_verification);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneHasBeenVerified, setPhoneHasBeenVerified] = useState(false);
  const [verificationCodeHasBeenSent, setVerificationCodeHasBeenSent] = useState(false);
  const [responseError, setResponseError] = useState({});
  const inputRefs = useRef([]);

  useEffect(() => {
    const handleGetTemplateSuccess = (data) => {
      setLoading(false);
      setPhoneNumber(data.phone_number);
      setPhoneHasBeenVerified(data.is_verified);
    };

    const fetchData = async () => {
      getFormTemplate(verifyPhoneTemplateId, formSessionId, csrfToken, handleGetTemplateSuccess, () => { });
    };

    if (formSessionId) {
      // this view isn't useful if we don't have a formSessionId yet, so don't even bother trying to load - the 
      // data we need won't be available anyway
      fetchData();
      setLoading(true);
    }
  }, [csrfToken, verifyPhoneTemplateId, setLoading, formSessionId, asModalView]);

  const handleNextTab = () => {
    if (isReadOnly) {
      goToNextTab();
      return;
    }
    submit();
  };

  const handlePrevTab = () => {
    goToPrevTab();
  };

  const handleSubmitSuccess = (data) => {
    if(data?.data?.redirect_url != null){
      window.location.href = data.data.redirect_url;
      return null;
    }
    updateRegistrationForms({
      id: data.id,
      form: data.form,
      templateId: data.form_template
    }, index); // this is mainly updated to the progress steps can check that the id exists and mark it as completed
    goToNextTab();
    setLoading(false);
  };

  const handleSubmitError = (errorData) => {
    setLoading(false);
    setResponseError(errorData);
  };

  const submit = () => {

    if (phoneHasBeenVerified) {
      goToNextTab();
      return;
    }
    if (!bypassPhoneVerification && !verificationCodeHasBeenSent) {
      setResponseError({ has_error: true, messages: ['Verification code has not been sent. Please click "Send Verification Code".'] })
      return;
    }
    if (!bypassPhoneVerification && !validateVerificationCode()) {
      return;
    }
    setLoading(true);
    setResponseError({});
    let formToSend = {
      id: registrationForm.id,
      form_session: formSessionId,
      form: {
        verification_code: verificationCode.join(""),
        bypass_phone_verification: bypassPhoneVerification
      },
      person: person.id,
      form_template: verifyPhoneTemplateId
    };
    if(isLastForm){
      formToSend.finalize = true;
    }
    if (!formToSend.id) {
      postFormSubmission(formToSend, formSessionId, csrfToken, handleSubmitSuccess, handleSubmitError);
    }
    else {
      putFormSubmission(formToSend.id, formToSend, formSessionId, csrfToken, handleSubmitSuccess, handleSubmitError);
    }
  };

  const handleInputChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);

    if (value && index < verificationCode.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSendVerificationCodeSuccess = (data) => {
    if(isLastForm && asModalView){ 
      window.parent.postMessage(MemberDashboardMessages.RELOAD_PARENT, process.env.REACT_APP_FABRIC_BASE_URL);
      return;
    }
    setVerificationCodeHasBeenSent(true)
    setPhoneNumber(data.data.phone_number);
    setLoading(false);
  };

  const handleSendVerificationCodeError = (errorData) => {
    setResponseError(errorData);
    setLoading(false);
  };
  const validateVerificationCode = () => {
    if (verificationCode.join("").length < 4) {
      setResponseError({ has_error: true, messages: ['Please enter all digits'] })
      return false;
    }
    return true;
  }
  const sendVerificationCode = (e) => {
    e.preventDefault();
    if(isLastForm && asModalView){ 
      window.parent.postMessage(MemberDashboardMessages.IS_SAVING, process.env.REACT_APP_FABRIC_BASE_URL);
      return;
    }
    if (phoneHasBeenVerified) {
      goToNextTab();
      return;
    }
    setLoading(true);
    postSendVerificationCode(formSessionId, csrfToken, handleSendVerificationCodeSuccess, handleSendVerificationCodeError);
  };

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl ">
      <div className="relative flex flex-col justify-center overflow-hidden px-4 py-6 sm:p-8">
        {responseError.has_error &&
          <div className="py-6">
            <Alert type="error" messages={responseError.messages} />
          </div>
        }
        {phoneHasBeenVerified &&
          <div className="py-6">
            <Alert type="success">
              Your phone number ({phoneNumber}) has been verified!
            </Alert>
          </div>
        }
        {!phoneHasBeenVerified &&
          <div className="relative bg-white w-full max-w-lg rounded-2xl">
            <div className="flex w-full mx-auto flex-col space-y-10">

              {!bypassPhoneVerification && verificationCodeHasBeenSent && (
                // If verification code has been sent, display the input form
                <form>
                  <div className="flex flex-col space-y-10">
                    <div className="flex flex-col items-left justify-center text-left">
                      <div className="flex flex-row font-medium  text-gray-500">
                        <p>We have sent a code to {phoneNumber}.</p>
                      </div>
                    </div>
                    <div className="flex flex-row items-left justify-between w-full max-w-xs">

                      {verificationCode.map((digit, index) => (
                        <div className="w-16 h-16" key={index}>
                          <input
                            ref={(input) => (inputRefs.current[index] = input)}
                            className="w-full h-full flex flex-col items-left justify-center text-center px-5 outline-none rounded-xl border border-gray-500 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-fabric-yellow"
                            type="text"
                            maxLength={1}
                            value={digit}
                            disabled={isReadOnly}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="flex flex-col space-y-5">
                      <div className="flex flex-row items-left text-left text-sm font-medium space-x-1 text-gray-500">
                        <p>Didn't receive code?</p>
                        <button className="flex flex-row items-left text-blue-600"
                          onKeyDown={(e) => e.preventDefault()}
                          onClick={sendVerificationCode}>
                          Resend
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {!bypassPhoneVerification && !verificationCodeHasBeenSent &&
                <div className="flex justify-left flex-col text-left  space-y-10">
                  <p>Your phone number is {phoneNumber}</p>
                  <CustomButton disabled={isReadOnly} className='w-full sm:w-1/2' onClick={sendVerificationCode}>Send Verification Code</CustomButton>
                </div>
              }

              <CustomCheckbox 
                value={bypassPhoneVerification} 
                name='bypassPhoneVerification' 
                label="I can't verify my phone number right now (skip this step)"
                onChange={setBypassPhoneVerification}
                labelFontWeightClass={'font-normal'}
                disabled={isReadOnly}
              />
            </div>
          </div>
        }
      </div>
      <PaginationButtons
        prevTabBtnText={prevTabTitle}
        handlePreviousTab={handlePrevTab}
        nextTabBtnText={nextTabTitle}
        handleNextTab={handleNextTab}
      />
    </div>
  );
};

export default VerifyPhone;